<template>
  <v-container>
   <v-alert
      :type="formType"
      v-model="formAlert"
      dense
      text
      dismissible
    >
    {{ formMsg }}
    </v-alert>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
    <v-card>
        <v-toolbar
          dark
          class="indigo darken-1"
          color="primary"
          id="visualBar"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Visualization</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="downloadImage"
            >
              Save Image
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div id="kgCanvas" />
     </v-card>
    </v-dialog>
  <v-card>
<!--    <v-row
    align="center"
    justify="center"
    >
    <v-col> -->
    <v-list>
      <v-list-item>
      <h3>User Feedback Form</h3>
      <v-btn
      @click="refreshTable"
      icon
      color="primary"
      dark
      >
        <v-icon>
          mdi-autorenew
        </v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
      color="primary"
      @click="submitForm"
      rounded
      >
        Submit
      </v-btn>
      </v-list-item>
    </v-list>
<!--    </v-col>
    </v-row> -->
  </v-card>
  <v-card>
    <v-data-table
    :headers="headers"
    :items="feedbackItems"
    loading-text="Loading... Please wait"
    ref="feedbackTable"
    >
    <template
    v-slot:item.equivalent="{ item }"
    >
      <v-row>
        <v-col>
          <v-checkbox
          v-model="item.equivalence"
          :label="`Yes`"
          value=true
          >
          </v-checkbox>
        </v-col>
        <v-spacer></v-spacer>
        <v-col>
          <v-checkbox
          v-model="item.equivalence"
          :label="`No`"
          value=false
          >
          </v-checkbox>
        </v-col>
      </v-row>
    </template>
    <template v-slot:item.graphData="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="showGraph(item)"
      >
        mdi-eye-outline
      </v-icon>
    </template>
    </v-data-table>
  </v-card>
  </v-container>
</template>

<script>
  import G6 from '@antv/g6'
  import api from '@/utils/api.js'
  var graph
  const tooltip = new G6.Tooltip({
    offsetX: 10,
    offsetY: 10,
    // the types of items that allow the tooltip show up
    // 允许出现 tooltip 的 item 类型
    trigger: 'click',
    itemTypes: ['node'],
    // custom the tooltip's content
    // 自定义 tooltip 内容
    getContent: (e) => {
      const outDiv = document.createElement('div')
      outDiv.style.width = 'fit-content'
      // outDiv.style.padding = '0px 0px 20px 0px';
      var attrContent = '<h4>Attributes</h4><ul>'
      const model = e.item.getModel()
      for (const key in model.attribute) {
        const value = model.attribute[key]
        attrContent += `<li>${key}: ${value}</li>`
      }
      attrContent += '</ul>'
      outDiv.innerHTML = attrContent
      return outDiv
    },
  })
  export default {
    graph,
    tooltip,
    data () {
      return {
        userId: null,
        colorSubjs: ['#0000FF', '#FF1493'],
        colorObjs: [],
        rawFeedbackData: {},
        subGraphData: undefined,
        dialog: false,
        formAlert: false,
        formMsg: '',
        formType: 'success',
        headers: [
          {
            text: 'KG1 Entity',
            value: 'nodeName1',
            sortable: false,
          },
          {
            text: 'KG2 Entity',
            value: 'nodeName2',
            sortable: false,
          },
          {
            text: 'Equivalent or Not',
            value: 'equivalent',
            sortable: false,
            align: 'center',
          },
          {
            text: 'Visualization',
            value: 'graphData',
            sortable: false,
            align: 'center',
          },
        ],
        feedbackItems: [],
      }
    },
    created () {
      this.userId = localStorage.getItem('taskId')
    },
    mounted () {
      const backColor = '#fff'
      const theme = 'default'
      const disableColor = '#777'
      this.colorObjs = G6.Util.getColorSetsBySubjectColors(
        this.colorSubjs,
        backColor,
        theme,
        disableColor,
      )
      this.refreshTable()
    },
    methods: {
      getFeedbackData (userId) {
        api.getFeedbackData(userId).then((res) => {
          if (res.status === 200) {
            this.rawFeedbackData = res.data
          }
        })
      },
      submitForm () {
        var msg = ''
        if (this.feedbackItems) {
          var formData = {
            taskId: this.userId,
            feedbackItems: [],
          }
          for (let i = 0; i < this.feedbackItems.length; ++i) {
            if (typeof (this.feedbackItems[i].equivalence) !== 'undefined') {
              formData.feedbackItems.push({
                sourceNode: this.feedbackItems[i].nodeName1,
                targetNode: this.feedbackItems[i].nodeName2,
                prob: this.feedbackItems[i].equivalence === 'true' ? 1.0 : 0.0,
              })
            }
          }
          if (formData.feedbackItems.length === 0) {
            this.formMsg = 'WARN: No feedback item available.'
            this.formType = 'warning'
            this.formAlert = true
            return
          }
          api.submitFeedback(formData).then((res) => {
            let formType = 'error'
            if (res.status === 200) {
              const success = res.data.success
              if (success) {
                formType = 'success'
                msg = 'Successfully submited ' + formData.feedbackItems.length.toString() + ' feedback mappings.'
              } else {
                msg = 'ERROR: ' + res.data.message
              }
            } else {
              msg = 'ERROR: Fail to get response from server.'
            }
            this.formMsg = msg
            this.formType = formType
            this.formAlert = true
          })
        } else {
          msg = 'ERROR: Fail to get feedback items.'
          this.formMsg = msg
          this.formType = 'error'
          this.formAlert = true
        }
      },
      refreshTable () {
        const uid = this.userId
        this.$refs.feedbackTable.loading = true
        api.getFeedbackData(uid).then((res) => {
          if (res.status === 200) {
            if (res.data.success) {
              this.rawFeedbackData = res.data
            }
            this.$refs.feedbackTable.loading = false
            return res.data.success
          } else {
            this.$refs.feedbackTable.loading = false
            return false
          }
        }).then((success) => {
          if (success) {
            this.optionDataGenerator()
            this.$refs.feedbackTable.loading = false
          }
        })
      },
      optionDataGenerator () {
        const markings = this.rawFeedbackData.data.markingList
        const colorObjs = this.colorObjs
        this.feedbackItems = markings.map((e) => {
          const sourceGraphData = this.graphDataHandler(e.sourceKG.subGraph, colorObjs[0], 'S')
          const targetGraphData = this.graphDataHandler(e.targetKG.subGraph, colorObjs[1], 'T')
          const graphData = {
            nodes: sourceGraphData.nodes.concat(targetGraphData.nodes),
            edges: sourceGraphData.edges.concat(targetGraphData.edges),
          }
          return Object.assign({}, {
            nodeName1: e.sourceKG.nodeName,
            nodeName2: e.targetKG.nodeName,
            equivalence: undefined,
            graphData: graphData,
          })
        })
      },
      graphDataHandler (rawSubgraph, colorObj, suffix) {
        var graphData = {
          nodes: [],
          edges: [],
        }
        const nodes = rawSubgraph.nodes
        const edges = rawSubgraph.edges
        graphData.nodes = nodes.map((item) => {
          return Object.assign({}, {
            id: item.nodeId + '-' + suffix,
            label: item.nodeName,
            groupId: suffix,
            attribute: item.attributes,
          })
        })
        const centerNodeId = rawSubgraph.centerNodeId
        const kgEdges = edges.map((item) => {
          let edgeColor
          if (item.sourceNodeId === centerNodeId || item.targetNodeId === centerNodeId) {
            edgeColor = colorObj.edgeHighlightStroke
          } else {
            edgeColor = colorObj.mainFill
          }
          const newStyle = Object.assign(item.style || {}, {
            endArrow: {
              path: 'M 25,0 L 30,2 L 30,-2 Z',
              stroke: edgeColor,
              fill: edgeColor,
            },
            stroke: edgeColor,
          })
          const newStateStyle = Object.assign(item.stateStyles || {}, {
            active: {
              stroke: colorObj.activeStroke,
              shadowColor: colorObj.activeStroke,
            },
            inactive: {
              fill: colorObj.inactiveFill,
              stroke: colorObj.inactiveStroke,
            },
            selected: {
              stroke: colorObj.selectedStroke,
              shadowColor: colorObj.selectedStroke,
            },
            highlight: {
              stroke: colorObj.highlightStroke,
            },
            disable: {
              stroke: colorObj.disableStroke,
            },
          })
          return Object.assign({}, {
            source: item.sourceNodeId + '-' + suffix,
            target: item.targetNodeId + '-' + suffix,
            label: item.edgeName,
            style: newStyle,
            stateStyles: newStateStyle,
          })
        })
        graphData.edges = kgEdges
        G6.Util.processParallelEdges(graphData.edges)
        graphData.nodes.forEach((i) => {
          i.cluster = i.groupId
          i.size = 50
          let newLabel = ''
          let len = 0
          for (let j = 0; j < i.label.length; ++j) {
            if (len >= 8) {
              newLabel += '\n'
              len = 0
            }
            newLabel += i.label.charAt(j)
            if (i.label.charCodeAt(j) > 255) {
              len += 2
            } else {
              len += 1
            }
          }
          i.label = newLabel
          i.style = Object.assign(i.style || {}, {
            fill: colorObj.mainFill,
            stroke: colorObj.mainStroke,
          })
          i.stateStyles = Object.assign(i.stateStyles || {}, {
            active: {
              fill: colorObj.activeFill,
              stroke: colorObj.activeStroke,
              shadowColor: colorObj.activeStroke,
            },
            inactive: {
              fill: colorObj.inactiveFill,
              stroke: colorObj.inactiveStroke,
            },
            selected: {
              fill: colorObj.selectedFill,
              stroke: colorObj.selectedStroke,
              shadowColor: colorObj.selectedStroke,
            },
            highlight: {
              fill: colorObj.highlightFill,
              stroke: colorObj.highlightStroke,
            },
            disable: {
              fill: colorObj.disableFill,
              stroke: colorObj.disableStroke,
            },
          })
        })
        return graphData
      },
      refreshDragedNodePosition (e) {
        const model = e.item.get('model')
        model.fx = e.x
        model.fy = e.y
      },
      initGraph () {
        if (this.graph) {
          this.graph.on('node:dragstart', e => {
            // 拖动节点时重新布局
            this.graph.layout()
            this.refreshDragedNodePosition(e)
          })
          this.graph.on('node:drag', e => {
            this.refreshDragedNodePosition(e)
          })
          this.graph.on('node:dragend', e => {
            e.item.get('model').fx = null
            e.item.get('model').fy = null
          })
          this.graph.on('node:mouseenter', (e) => {
            this.graph.setItemState(e.item, 'active', true)
          })
          this.graph.on('node:mouseleave', (e) => {
            this.graph.setItemState(e.item, 'active', false)
          })
          this.graph.on('edge:mouseenter', (e) => {
            this.graph.setItemState(e.item, 'active', true)
          })
          this.graph.on('edge:mouseleave', (e) => {
            this.graph.setItemState(e.item, 'active', false)
          })
        }
      },
      showGraph (data) {
        this.subGraphData = data.graphData
        this.dialog = true
        this.$nextTick(() => {
          if (!this.graph) {
            // const container = document.getElementById('kgCanvas')
            const toolBar = document.getElementById('visualBar')
            const style = window.getComputedStyle(toolBar)
            var toolBarHeight = style.getPropertyValue('height')
            var width = window.innerWidth
            var height = (document.documentElement.clientHeight - parseInt(toolBarHeight)) || 500
            this.graph = new G6.Graph({
              container: 'kgCanvas',
              width: width,
              height: height,
              animate: false,
              fitCenter: true,
              linkCenter: true,
              fitView: true,
              plugins: [tooltip],
              layout: {
                type: 'force',
                linkDistance: 500,
                preventOverlap: true,
                clustering: true,
                clusterNodeStrength: 1,
                // clusterEdgeDistance: 300,
                clusterNodeSize: 50,
                clusterFociStrength: 1.5,
                nodeSpacing: 5,
              },
              modes: {
                default: ['zoom-canvas', 'drag-canvas', 'drag-node'],
              },
              defaultNode: {
                size: [30, 30],
                labelCfg: {
                  style: {
                    fontSize: 10,
                  },
                },
              },
              defaultEdge: {
                type: 'quadratic',
                style: {
                  endArrow: {
                    path: 'M 25,0 L 30,2 L 30,-2 Z',
                    fill: '#e2e2e2',
                  },
                },
                labelCfg: {
                  autoRotate: true,
                  style: {
                    fontSize: 8,
                  },
                },
              },
            })
            this.initGraph()
            this.graph.data(this.subGraphData)
            this.graph.render()
            window.onresize = () => {
              if (!this.graph || this.graph.get('destroyed')) return
              toolBarHeight = style.getPropertyValue('height')
              width = window.innerWidth
              height = (document.documentElement.clientHeight - parseInt(toolBarHeight)) || 500
              this.graph.changeSize(width, height)
            }
          } else {
            this.graph.changeData(this.subGraphData)
          }
        })
      },
      downloadImage () {
        if (this.graph) {
          this.graph.downloadFullImage('feedback-pair', 'image/png', {
            padding: [10, 15, 15, 15],
          })
        }
      },
    },
    // watch: {
    //   dialog (val, oldVal) {
    //     alert(val)
    //   },
    // },
  }
</script>

<style>
  #kgCanvas {
    margin: 0px 0px;
    /* height: 500px; */
/*    width: 500px; */
    /* max-height: 500px; */
    /* padding: 10px 10px; */
    overflow-y: hidden;
    overflow-x: hidden;
    /* border: 1px solid red; */
  }
  .wrapper {
    display: flex;
    align-items: center;
    margin: 0px 10px;
  }
</style>
